<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>消息推送管理</el-breadcrumb-item>
    </el-breadcrumb>
    <hr style="margin-bottom: 20px" />
    <el-form ref="formData" :model="formData" label-width="120px">
      <el-form-item label="标题">
        <el-input v-model="formData.title" maxlength="30"></el-input>
      </el-form-item>
      <el-form-item label="内容">
        <el-input
          v-model="formData.content"
          maxlength="100"
          type="textarea"
          size="medium"
        ></el-input>
      </el-form-item>
      <el-form-item label="范围">
        <el-select placeholder="请选择范围" v-model="formData.type">
          <el-option
            v-for="item in scope"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button
          size="mini"
          type="primary"
          style="margin: 20px 20px 0 0"
          @click="sumitForm('formData')"
          >确定</el-button
        >
        <el-button
          size="mini"
          type="primary"
          style="margin: 20px 0"
          @click="$router.go(-1)"
          >返回</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { addPush, pushDetail } from "../../api/appManage";
export default {
  data() {
    return {
      scope: [
        { value: 0, text: "全部" },
        { value: 1, text: "IOS" },
        { value: 2, text: "Android" },
      ],
      versionId: "",
      formData: {
        pushId: "0",
        title: "",
        content: "",
        type: "",
      },
    };
  },
  created() {
    console.log("laskdjlask");
    this.formData.pushId = this.$route.query.id;
    if (this.formData.pushId !== "0") {
      this.getFormDetail();
    }
  },

  methods: {
    async getFormDetail() {
      const { data } = await pushDetail({
        pushId: this.formData.pushId,
      });
      if (data.code == 0) {
        this.formData = data.data[0];
      }
    },
    async onAddPush() {
      let that = this;
      const { data } = await addPush(this.formData);
      if (data.code == 0) {
        this.$message({
          type: "success",
          message: data.msg,
        });
        setTimeout(() => {
          that.$router.go(-1);
        }, 500);
      } else {
        this.$message({
          type: "error",
          message: data.msg,
        });
      }
    },
    sumitForm(formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          this.onAddPush();
        } else {
          this.onAddPush();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input__inner {
  width: 300px !important;
}
/deep/.el-textarea__inner {
  width: 500px !important;
  height: 200px !important;
}
.title {
  font-weight: bold;
  margin-left: -90px;
  span {
    height: 13px;
    width: 5px;
    background-color: #3082fd;
    display: inline-block;
    margin-right: 5px;
  }
}
</style>